<template>
  <div  class="px-15px px-lg-25px">
    <div class="card">
       <form >
        <div class="card-header row gutters-5">
          <div class="col text-center text-md-left">
            <h5 class="mb-md-0 h6">{{ $t('yong-jin-li-shi') }}</h5>
          </div>
          <div class="col-md-3 ml-auto">
            <a-range-picker v-model="form.date" size="large" />
          </div>
          <button class="btn btn-primary input-group-append" type="submit">{{ $t('shai-xuan') }}</button>
        </div>
      </form>

      <div class="card-body p-3">
        <table class="table mb-0"  :class="tableData.length == 0 ? ' footable aiz-table' : ''">
          <thead>
            <tr>
              <th>#</th>
              <th v-show="false">{{ $t('ding-dan-bian-hao') }}</th>
              <th>{{ $t('shou-ru') }}</th>
              <th>{{ $t('chuang-jian-ri-qi') }}</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, i) in tableData" :key="i">
              <td>{{ i + 1 }}</td>
              <td v-show="false">
                {{item.RecordId}}
              </td>
              <td>{{item.Practical}}</td>
              <td>{{item.CreatedAt}}</td>
              </tr>
              <tr class="footable-empty" v-if="tableData.length == 0"><td colspan="5">{{ $t('shi-mo-du-mei-zhao-dao') }}</td></tr>
          </tbody>
        </table>
        <div class="aiz-pagination mt-2" v-if="page.total > 1">
          <pageBar :totalPage="page.total" :page="page.page" @change="changePage"></pageBar>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import pageBar from '@/components/page'
import { payDetailList } from '@/api/user'
export default {
  components: {
    pageBar
  },
  data() {
    return {
      form: {
        deliverStatus: '',
        payStatus: ''
      },
      statusList1: [],
      statusList2: [],
      hasPackage: true,
      packageUrl: require('../../../assets/imgs/package.png'),
      tableData: [],
      page: {
        page: 1,
        total: 1,
        limit: 10
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    changePage(page) {
      this.page.page = page
      this.init()
    },
    init() {
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      form.append('Type', 10)
      payDetailList(form).then(res => {
        this.tableData = res.data.Items
        this.page.total = res.data.Pagination ? res.data.Pagination.totalPage : 1
      })
    }
  }
}
</script>